/* eslint-disable */

import { useEffect } from "react";
import { ReactComponent as Svg } from "../assets/icon/workingprogress.svg";

export default function NotFound() {
    useEffect(() => {

    }, []);
    return (
        <>
        <Svg/>
        </>
    )
  }