import React, { useEffect, useRef } from 'react';
import markerIcon from '../assets/oroPrestige/marker30.svg';
import jsonData from '../assets/oroPrestige/jewelers.json';


const MapOP = () => {
  const infoWindowRef = useRef(null);
  const userLocationMarkerRef = useRef(null);
  const customMarkerRef = useRef(null);



  const generateSmartLink = (latitude, longitude) => {
    const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);

    if (isIOS) {
      return `maps://maps.apple.com/?daddr=${latitude},${longitude}&dirflg=d`;
    }

    if (isAndroid) {
      return `https://maps.google.com/maps?daddr=${latitude},${longitude}&dirflg=d`;
    }

    // Fallback link for other platforms or when the mobile app is not available
    return `https://maps.google.com/maps?daddr=${latitude},${longitude}`;
  };

  useEffect(() => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      mapId: 'd308f25bf4d2b448',
      center: { lat: 45.530986, lng: 10.941758 },
      zoom: 10.2,
      clickableIcons: true,
    });

    let markerIndex = 0;
    const markers = [];

    const dropMarker = () => {
      if (markerIndex < jsonData.length) {
        const item = jsonData[markerIndex];

        const marker = new window.google.maps.Marker({
          position: { lat: item.lat, lng: item.lng },
          map: map,
          icon: markerIcon,
          title: item.nome,
          animation: window.google.maps.Animation.DROP,
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: `
            <a class="text-decoration-none" target="_blank"  href="${generateSmartLink(
                item.lat,
                item.lng
              )}">
              <p class="nomeG fw-bold m-0 text-center text-dark">${item.nome}</p>
              <p class="m-0 text-dark text-center">${item.indirizzo}</p>
              <p class="m-0 text-link fw-bold text-center">ottieni indicazioni</p>
            </a>
          `,
        });

        marker.addListener('click', () => {
          if (infoWindowRef.current) {
            infoWindowRef.current.close();
          }
          infoWindow.open(map, marker);
          infoWindowRef.current = infoWindow;
        });

        markers.push(marker);

        markerIndex++;
        setTimeout(dropMarker, 50); // Delay between markers in milliseconds (e.g., 1000 = 1 second)
      }
    };

    dropMarker();

        // Add current location button
        const currentLocationBtn = document.createElement('button');
        currentLocationBtn.innerHTML = `<i class="fa-solid fa-crosshairs fs-5 text-dark text-opacity-75"></i>`;
        currentLocationBtn.classList.add('current-location-btn');
    
        const centerMapToCurrentLocation = () => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const { latitude, longitude } = position.coords;
                  map.setCenter({ lat: latitude, lng: longitude });
                  map.setZoom(12);

          
                  // Create user location marker
                  if (userLocationMarkerRef.current) {
                    userLocationMarkerRef.current.setPosition({ lat: latitude, lng: longitude });
                  } else {
                    const userLocationMarker = new window.google.maps.Marker({
                      position: { lat: latitude, lng: longitude },
                      map: map,
                      title: 'La mia posizione',
                      animation: window.google.maps.Animation.DROP,
                    });
                    userLocationMarkerRef.current = userLocationMarker;
                  }
                },
                (error) => {
                  console.error('Error getting current location:', error);
                }
              );
            } else {
              console.error('Geolocation is not supported by this browser.');
            }
          };
        currentLocationBtn.addEventListener('click', centerMapToCurrentLocation);
        map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(currentLocationBtn);
          
      


      

    return () => {
      // Clean up markers and close the info window on unmount
      markers.forEach((marker) => marker.setMap(null));
      if (infoWindowRef.current) {
        infoWindowRef.current.close();
      }
    };
  }, []);

  return <div id="map" className="rounded" style={{"height":"20rem","width":"20rem"}}></div>;
};

export default MapOP;
