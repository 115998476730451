import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import bodyImg1 from "../assets/prodotti/AMORE_blu_1.1.webp"
import bodyImg2 from "../assets/prodotti/DESIDERIO_blu_1.1.webp"
import bodyImg3 from "../assets/prodotti/SOGNO_blu_1.1.webp"
import bodyImg4 from "../assets/prodotti/FANTASIA_blu_1.1.webp"
import bodyImg5 from "../assets/prodotti/JB MATT_blu_1.1.webp"
import bodyImg6 from "../assets/prodotti/JB STAR_blu_1.1.webp"
import CallToAction from "../components/CallToAction";
import { useEffect } from "react";
import { BsWhatsapp } from 'react-icons/bs'; 


function Prodotti(){   
    
    useEffect(() => {
        sessionStorage.setItem('loadingPageShown', 'true');      
        }, []);

    const handleLinkClick = () => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      };

    return(
        <div className="bg-marmo">
            <Helmet>
                <title>Pietro Rinaldi Parrucchiere: Prodotti</title>
            </Helmet>
            <Navbar/>
            <div className="container-fluid custom-margin-top bg-dark bg-opacity-75 pb-5">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center my-lg-4">
                        <div className="col-lg-12 col-md-10 col-11">
                            <h1 className="text-center pt-5 text-animate-gold fw-bold display-2 text-uppercase m-0">Prodotti</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid d-block d-md-none">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
                    <div className="bg-dark">
                    <h2 className="fw-bold text-animate-gold text-uppercase my-3 text-center">Linea Pietro</h2>
                    </div>
                    <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
                    <div className="col-12 m-0 p-0">
                        <div className="container bg-dark bg-opacity-75 pb-5">
                            <div className="row d-flex justify-content-around align-items-center pt-5">
                                <div className="col-4 m-0 p-0">
                                    <img src={bodyImg1} className="img-fluid rounded" alt="immagine stretta di mano"/>
                                </div>
                                <div className="col-5 text-start p-0">
                                    <p className="p-0 m-0 display-4 text-default fw-bold">
                                    Amore
                                    </p>
                                    <p className="fs-5-psc text-light ">
                                    Finishing Antiumidità
                                    </p>
                                    <a onClick={handleLinkClick} className="" target="_blank" rel="noopener" href="https://wa.me/3429396971?text=Ciao!%20Vorrei%20più%20informazioni%20riguardo%20a:%20AMORE"><button className="btn btn-default px-3 py-2 rounded">
                                        <BsWhatsapp className="fs-1 me-2 text-light"/>
                                        Chiedi Info
                                    </button></a>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-around align-items-center mt-4">
                                <div className="col-4 m-0 p-0">
                                    <img src={bodyImg2} className="img-fluid rounded" alt="immagine stretta di mano"/>
                                </div>
                                <div className="col-5 text-start p-0">
                                    <p className="p-0 m-0 display-4 text-default fw-bold">
                                    Desiderio
                                    </p>
                                    <p className="fs-5-psc text-light ">
                                    Hair Care Shampoo
                                    </p>
                                    <a onClick={handleLinkClick} className="" target="_blank" rel="noopener" href="https://wa.me/3429396971?text=Ciao!%20Vorrei%20più%20informazioni%20riguardo%20a:%20DESIDERIO"><button className="btn btn-default px-3 py-2 rounded">
                                        <BsWhatsapp className="fs-1 me-2 text-light"/>
                                        Chiedi Info
                                    </button></a>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-around align-items-center mt-4">
                                <div className="col-4 m-0 p-0">
                                    <img src={bodyImg3} className="img-fluid rounded" alt="immagine stretta di mano"/>
                                </div>
                                <div className="col-5 text-start p-0">
                                    <p className="p-0 m-0 display-4 text-default fw-bold">
                                    Sogno
                                    </p>
                                    <p className="fs-5-psc text-light ">
                                    Finishing Mousse
                                    </p>
                                    <a onClick={handleLinkClick} className="" target="_blank" rel="noopener" href="https://wa.me/3429396971?text=Ciao!%20Vorrei%20più%20informazioni%20riguardo%20a:%20SOGNO"><button className="btn btn-default px-3 py-2 rounded">
                                        <BsWhatsapp className="fs-1 me-2 text-light"/>
                                        Chiedi Info
                                    </button></a>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-around align-items-center mt-4">
                                <div className="col-4 m-0 p-0">
                                    <img src={bodyImg4} className="img-fluid rounded" alt="immagine stretta di mano"/>
                                </div>
                                <div className="col-5 text-start p-0">
                                    <p className="p-0 m-0 display-4 text-default fw-bold">
                                    Fantasia
                                    </p>
                                    <p className="fs-5-psc text-light ">
                                    Finishing Lacca
                                    </p>
                                    <a onClick={handleLinkClick} className="" target="_blank" rel="noopener" href="https://wa.me/3429396971?text=Ciao!%20Vorrei%20più%20informazioni%20riguardo%20a:%20FANTASIA"><button className="btn btn-default px-3 py-2 rounded">
                                        <BsWhatsapp className="fs-1 me-2 text-light"/>
                                        Chiedi Info
                                    </button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
                    <div className="bg-dark">
                    <h2 className="fw-bold text-animate-gold text-uppercase text-center my-3">Linea Walter</h2>
                    </div>
                    <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
                    <div className="col-12 m-0 p-0">
                        <div className="container bg-dark bg-opacity-75 py-5">
                            <div className="row d-flex justify-content-around align-items-center mt-4">
                                <div className="col-5 text-end p-0">
                                    <p className="p-0 m-0 display-4 text-default fw-bold">
                                    JB MATT
                                    </p>
                                    <p className="fs-5-psc text-light ">
                                    Finishing Cera Opaca
                                    </p>
                                    <a onClick={handleLinkClick} className="" target="_blank" rel="noopener" href="https://wa.me/3429396971?text=Ciao!%20Vorrei%20più%20informazioni%20riguardo%20a:%20JB%20MATT"><button className="btn btn-default px-3 py-2 rounded">
                                        <BsWhatsapp className="fs-1 me-2 text-light"/>
                                        Chiedi Info
                                    </button></a>
                                </div>
                                <div className="col-4 m-0 p-0">
                                    <img src={bodyImg5} className="img-fluid rounded" alt="immagine stretta di mano"/>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-around align-items-center mt-4">
                                <div className="col-5 text-end p-0">
                                    <p className="p-0 m-0 display-4 text-default fw-bold">
                                    JB STAR
                                    </p>
                                    <p className="fs-5-psc text-light ">
                                    Finishing Cera Lucida
                                    </p>
                                    <a onClick={handleLinkClick} className="" target="_blank" rel="noopener" href="https://wa.me/3429396971?text=Ciao!%20Vorrei%20più%20informazioni%20riguardo%20a:%20JB%20STAR"><button className="btn btn-default px-3 py-2 rounded">
                                        <BsWhatsapp className="fs-1 me-2 text-light"/>
                                        Chiedi Info
                                    </button></a>
                                </div>
                                <div className="col-4 m-0 p-0">
                                    <img src={bodyImg6} className="img-fluid rounded" alt="immagine stretta di mano"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
            </div>
{/* desktop */}
            <div className="container-fluid d-none d-md-block">
                <div className="row d-flex justify-content-center align-items-top bg-dark bg-opacity-75">
                    <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
                    <div className="row d-flex justify-content-around align-items-center bg-dark">
                        <div className="col-6">
                            <h2 className="fw-bold text-animate-gold text-uppercase my-3 text-center">Linea Pietro</h2>
                        </div>
                        <div className="col-6">
                            <h2 className="fw-bold text-animate-gold text-uppercase text-center my-3">Linea Walter</h2>
                        </div>
                    </div>
                    <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
                    <div className="col-5 m-0 p-0">
                        <div className="container pb-5">
                            <div className="row d-flex justify-content-evenly align-items-center pt-5">
                                <div className="col-4 m-0 p-0">
                                    <img src={bodyImg1} className="img-fluid rounded" alt="immagine stretta di mano"/>
                                </div>
                                <div className="col-6 col-md-8 col-lg-5 text-start p-0">
                                    <p className="p-0 m-0 display-4 text-default fw-bold">
                                    Amore
                                    </p>
                                    <p className="fs-5-psc text-light ">
                                    Finishing Antiumidità
                                    </p>
                                    <a onClick={handleLinkClick} className="" target="_blank" rel="noopener" href="https://wa.me/3429396971?text=Ciao!%20Vorrei%20più%20informazioni%20riguardo%20a:%20AMORE"><button className="btn btn-default px-3 py-2 rounded">
                                        <BsWhatsapp className="fs-1 me-2 text-light"/>
                                        Chiedi Info
                                    </button></a>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-evenly align-items-center mt-4">
                                <div className="col-4 m-0 p-0">
                                    <img src={bodyImg2} className="img-fluid rounded" alt="immagine stretta di mano"/>
                                </div>
                                <div className="col-6 col-md-8 col-lg-5 text-start p-0">
                                    <p className="p-0 m-0 display-4 text-default fw-bold">
                                    Desiderio
                                    </p>
                                    <p className="fs-5-psc text-light ">
                                    Hair Care Shampoo
                                    </p>
                                    <a onClick={handleLinkClick} className="" target="_blank" rel="noopener" href="https://wa.me/3429396971?text=Ciao!%20Vorrei%20più%20informazioni%20riguardo%20a:%20DESIDERIO"><button className="btn btn-default px-3 py-2 rounded">
                                        <BsWhatsapp className="fs-1 me-2 text-light"/>
                                        Chiedi Info
                                    </button></a>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-evenly align-items-center mt-4">
                                <div className="col-4 m-0 p-0">
                                    <img src={bodyImg3} className="img-fluid rounded" alt="immagine stretta di mano"/>
                                </div>
                                <div className="col-6 col-md-8 col-lg-5 text-start p-0">
                                    <p className="p-0 m-0 display-4 text-default fw-bold">
                                    Sogno
                                    </p>
                                    <p className="fs-5-psc text-light ">
                                    Finishing Mousse
                                    </p>
                                    <a onClick={handleLinkClick} className="" target="_blank" rel="noopener" href="https://wa.me/3429396971?text=Ciao!%20Vorrei%20più%20informazioni%20riguardo%20a:%20SOGNO"><button className="btn btn-default px-3 py-2 rounded">
                                        <BsWhatsapp className="fs-1 me-2 text-light"/>
                                        Chiedi Info
                                    </button></a>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-evenly align-items-center mt-4">
                                <div className="col-4 m-0 p-0">
                                    <img src={bodyImg4} className="img-fluid rounded" alt="immagine stretta di mano"/>
                                </div>
                                <div className="col-6 col-md-8 col-lg-5 text-start p-0">
                                    <p className="p-0 m-0 display-4 text-default fw-bold">
                                    Fantasia
                                    </p>
                                    <p className="fs-5-psc text-light ">
                                    Finishing Lacca
                                    </p>
                                    <a onClick={handleLinkClick} className="" target="_blank" rel="noopener" href="https://wa.me/3429396971?text=Ciao!%20Vorrei%20più%20informazioni%20riguardo%20a:%20FANTASIA"><button className="btn btn-default px-3 py-2 rounded">
                                        <BsWhatsapp className="fs-1 me-2 text-light"/>
                                        Chiedi Info
                                    </button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 m-0 p-0">
                        <div className="container py-5">
                            <div className="row d-flex justify-content-evenly align-items-center">
                                <div className="col-4 m-0 p-0">
                                    <img src={bodyImg5} className="img-fluid rounded" alt="immagine stretta di mano"/>
                                </div>
                                <div className="col-6 col-md-8 col-lg-5 text-end p-0">
                                    <p className="p-0 m-0 display-4 text-default fw-bold">
                                    JB MATT
                                    </p>
                                    <p className="fs-5-psc text-light ">
                                    Finishing Cera Opaca
                                    </p>
                                    <a onClick={handleLinkClick} className="" target="_blank" rel="noopener" href="https://wa.me/3429396971?text=Ciao!%20Vorrei%20più%20informazioni%20riguardo%20a:%20JB%20MATT"><button className="btn btn-default px-3 py-2 rounded">
                                        <BsWhatsapp className="fs-1 me-2 text-light"/>
                                        Chiedi Info
                                    </button></a>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-evenly align-items-center mt-4">
                                <div className="col-4 m-0 p-0">
                                    <img src={bodyImg6} className="img-fluid rounded" alt="immagine stretta di mano"/>
                                </div>
                                <div className="col-6 col-md-8 col-lg-5 text-end p-0">
                                    <p className="p-0 m-0 display-4 text-default fw-bold">
                                    JB STAR
                                    </p>
                                    <p className="fs-5-psc text-light ">
                                    Finishing Cera Lucida
                                    </p>
                                    <a onClick={handleLinkClick} className="" target="_blank" rel="noopener" href="https://wa.me/3429396971?text=Ciao!%20Vorrei%20più%20informazioni%20riguardo%20a:%20JB%20STAR"><button className="btn btn-default px-3 py-2 rounded">
                                        <BsWhatsapp className="fs-1 me-2 text-light"/>
                                        Chiedi Info
                                    </button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
            </div>
            <CallToAction/>
            <Footer/>
        </div>
    )
};

export default Prodotti