import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import TagManager from 'react-gtm-module';
import "./index.css";

const tagManagerArgs = {
  gtmId: 'GTM-5B3MSKPT',
};

TagManager.initialize(tagManagerArgs);

const Root = () => {


  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(<Root />);
