import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import galleryData from '../assets/gallery/pietrorinaldiparrucchiere-gallery.json';
import Fuse from 'fuse.js';

const SearchBar = ({ onSuggestionsChange }) => {
  const [value, setValue] = useState('');
  const [posts, setPosts] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setPosts(galleryData);
  }, []);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
      if (newValue === '') {
        // Call onClear when Backspace key is pressed and the input is empty
        onSuggestionsChange(posts);
      }
  };

  // const onSuggestionsFetchRequested = ({ value }) => {
  //   const inputValue = value.toLowerCase().trim(); 
  //   let uniqueSuggestions = new Set();
  //   if (inputValue.length >= 2) {
  //     onSuggestionsChange(posts);
  //     posts.forEach((post) => {
  //       if (
  //         post.Title.toLowerCase().includes(inputValue) ||
  //         post.Keywords.toLowerCase().includes(inputValue)
  //       ) {
  //         uniqueSuggestions.add(post.Title);
  //       }
  //     });
  //   }
  //   const filteredPosts = posts.filter((post) =>
  //   post.Title.toLowerCase().includes(inputValue) ||
  //   post.Keywords.toLowerCase().includes(inputValue)
  // );

  
  //   onSuggestionsChange(filteredPosts);
  //   const limitedSuggestions = Array.from(uniqueSuggestions).slice(0, 3); // Limita ai primi 3 suggerimenti
  //   setSuggestions(limitedSuggestions);
  // };

  const onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.toLowerCase().trim();
    const inputValues = inputValue.split(' ');
  
    let uniqueSuggestions = new Set();
    let filteredPosts = [];
  
    const fuse = new Fuse(posts, {
      keys: ['Title', 'Keywords', 'CommonWord'],
      threshold: 0.6,
    });
  
    if (inputValue.length >= 1 || inputValues.length > 0) {
      const searchResults = fuse.search(inputValue);
    
      filteredPosts = searchResults.map((result) => result.item);
      filteredPosts.forEach((post) => {
        uniqueSuggestions.add(post.Title);
      });
    }
    
    const limitedSuggestions = filteredPosts && filteredPosts.length > 0 ? Array.from(uniqueSuggestions).slice(0, 3) : [];

    console.log("suggerimenti", filteredPosts)
  
    onSuggestionsChange(filteredPosts);
    setSuggestions(limitedSuggestions);
  };
  ////////////////////////////////////////////////////////////////

  const onSuggestionsClearRequested = () => {
    setSuggestions([posts]);

  };

  const inputProps = {
    placeholder: 'Cerca...',
    value,
    onChange,
  };

  const renderInputComponent = (inputProps) => (
    <div className="inputContainer">
      <FontAwesomeIcon icon={faMagnifyingGlass} className="searchIcon text-default" />
      <input
        {...inputProps}
        type="text"
        className="border-1 rounded-pill gallery-search-bar"
      />
    </div>
  );

  const handleSuggestionSelected = (_, { suggestion }) => {
    const matchingPosts = posts.filter((post) => post.Title === suggestion);

    // setValue(suggestion);
    // onSuggestionsFetchRequested({ value: suggestion });
    setValue('');
    setSuggestions([]);
  
    // You can also call onSuggestionsChange if needed
    onSuggestionsChange(matchingPosts); 
  };

  return (
    <div className="text-start">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={(suggestion) => (
          <div>
            <p className="text-decoration-none text-dark-mode p-0 m-0">
              {suggestion}
            </p>
          </div>
        )}
        inputProps={inputProps}
        onSuggestionSelected={handleSuggestionSelected}
        renderInputComponent={renderInputComponent}
      />
    </div>
  );
};

export default SearchBar;
