import React from 'react'
import logo from "../assets/logo/logo2.png"


const Footer = () => {

    const handleFooterLinkClick = () => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      };


  return (
    <div>
        <footer className="footer bg-black py-4">
            <div className="container-fluid">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-12 col-md-3  d-flex align-items-center">
                        <img src={logo} className="img-fluid custom-logo-size" alt="logo"/>
                    </div>
                    <div className="mt-4 col-12 col-md-3 text-light text-center text-lg-start">
                        <p className="m-0 text-light-dark-mode">Pietro Rinaldi Parrucchiere di Pietro Rinaldi</p>
                        <p className="m-0 text-light-dark-mode">P.IVA 03178360230</p>
                        <p className="m-0 text-light-dark-mode">Via Del Combattente, 3/B, 37024 Negrar - Verona</p>
                    </div>

                    <div className="col-md-5 col-xl-3 text-md-start text-center mb-5 pb-5 mt-4">
                    &nbsp;                        {/* <a className="link-light text-decoration-none me-3" target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/95086304">Privacy Policy</a>
                        <a className="link-light text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/95086304/cookie-policy">Cookie Policy</a> */}
                    </div>

                </div>
            </div>
        </footer>
    </div>
  )
}

export default Footer