import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../assets/logo/logo1.png';

const Navbar = () => {

  const navigate = useNavigate();

    // State to track the current mode (dark or light)
    const [darkMode, setDarkMode] = useState(false);

    // Function to toggle the mode
    const toggleDarkMode = () => {
      setDarkMode((prevMode) => !prevMode);
    };

      // Function to add/remove dark mode class to the body
    const updateDarkModeClass = () => {
      const body = document.body;
      if (darkMode) {
        body.classList.add('dark-mode');
      } else {
        body.classList.remove('dark-mode');
      }
    };

    React.useEffect(() => {
      updateDarkModeClass();
    }, [darkMode]);



  const handleNavLinkClick = () => {
    setTimeout(() => {
      const navbarCollapse = document.querySelector("#navbarResponsive");
      if (navbarCollapse.classList.contains("show")) {
        navbarCollapse.classList.remove("show");
      }

      window.scrollTo(0, 0);
      const navbarToggler = document.querySelector(".navbar-toggler");
      navbarToggler.setAttribute("aria-expanded", "false");
    }, 100);
  };

  const GoContact = (callback) => {
    setTimeout(() => {
      const navbarCollapse = document.querySelector("#navbarResponsive");
      const element = document.getElementById("contatti");
      if (navbarCollapse.classList.contains("show")) {
        navbarCollapse.classList.remove("show");
      }
      window.scrollTo(0, 4000);
      const navbarToggler = document.querySelector(".navbar-toggler");
      navbarToggler.setAttribute("aria-expanded", "false");
    }, 100);
  };
  
  const handleBodyClick = (event) => {
    const navbarCollapse = document.querySelector("#navbarResponsive");
    if (
      navbarCollapse.classList.contains("show") &&
      !event.target.closest("#navbarResponsive") &&
      !event.target.closest(".navbar-toggler")
    ) {
      navbarCollapse.classList.remove("show");
      const navbarToggler = document.querySelector(".navbar-toggler");
      navbarToggler.setAttribute("aria-expanded", "false");
    }
  };

  document.body.addEventListener("click", handleBodyClick);

  const handleNavbarTogglerClick = () => {
    const navbarCollapse = document.querySelector("#navbarResponsive");
    const navbarToggler = document.querySelector(".navbar-toggler");
    if (navbarCollapse.classList.contains("show")) {
      navbarCollapse.classList.remove("show");
      navbarToggler.setAttribute("aria-expanded", "false");
    } else {
      navbarCollapse.classList.add("show");
      navbarToggler.setAttribute("aria-expanded", "true");
    }
  };
  


  return (
    <div className="">
       <nav className="navbar navbar-expand-lg bg-dark fixed-top shadow navbar-main" id="mainNav">
            <div className="container" id="navbarGP">
                <Link className="navbar-brand" to="/" onClick={handleNavLinkClick}>
                  <img className="img-fluid l-size" src={Logo} alt="logo" />
                </Link>
                <button
                  className="navbar-toggler icon_menu border-0"
                  type="button"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={handleNavbarTogglerClick}
                >
                  <span></span>
                </button>

                <div className="collapse navbar-collapse py-4" id="navbarResponsive">
                    <ul className="navbar-nav text-uppercase  py-4 py-lg-0 d-flex ms-auto text-center">
                        <li className="nav-item"><Link className="nav-link text-light text-opacity-75" onClick={handleNavLinkClick} to="/">Home</Link></li>
                        <li className="nav-item"><Link className="nav-link text-light text-opacity-75" onClick={handleNavLinkClick} to="/gallery">Gallery</Link></li>
                        <li className="nav-item"><Link className="nav-link text-light text-opacity-75" onClick={handleNavLinkClick} to="/prodotti">Prodotti</Link></li>
                        <li className="nav-item"><Link className="nav-link text-light text-opacity-75" onClick={GoContact} to="/">Contatti</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
  )
}

export default Navbar