import React, { useState, useEffect } from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SearchBar from "../components/SearchBar"
import galleryData from '../assets/gallery/pietrorinaldiparrucchiere-gallery.json';
import { Helmet } from 'react-helmet';


const Gallery = () => {
 const [posts, setPosts] = useState([]);
 const [visiblePosts, setVisiblePosts] = useState(9);
 const [filteredPosts, setFilteredPosts] = useState(posts);
 const [hasPosts, setHasPosts] = useState(true);
 

 useEffect(() => {
  sessionStorage.setItem('loadingPageShown', 'true');

  }, []);

 const images = {};

 function importAll(r) {
   r.keys().forEach((item) => {
     images[item.replace('./', '')] = r(item);
   });
 }
 
 importAll(require.context('../assets/slidehome', false, /\.(webp|jpe?g|svg|png)$/));

 const handleShowMore = () => {
  setVisiblePosts(prevVisiblePosts => prevVisiblePosts + 9);
}

const handleShowLess = () => {
  if (visiblePosts > 9) {
    setVisiblePosts(prevVisiblePosts => prevVisiblePosts - 9);
    }
}

useEffect(() => {
    setPosts(galleryData);
    setFilteredPosts(galleryData);
    setHasPosts(galleryData.some(post => post.Stato === 1));
}, []);

  const handleSuggestionsChange = (suggestions) => {
    setFilteredPosts(suggestions);
  };


  function ImageComponent(props) {

    const [currentImage, setCurrentImage] = useState(null);
  
    const toggleFullScreen = (event) => {
      event.stopPropagation();
      setCurrentImage(props.imageUrl);
      document.body.style.overflow = 'hidden';
    };
  
    const closeFullScreen = () => {
      setCurrentImage(null);
      document.body.removeAttribute('style');
    };

    console.log(images[props.imageUrl])
      
    return (
      <div>
        <img src={images[props.imageUrl]} className="img-fluid" onClick={toggleFullScreen} alt="immagine evento"/>
        {currentImage === props.imageUrl && (
          <div className="full-screen-overlay" onClick={closeFullScreen}>
            <img src={images[props.imageUrl]} alt="immagine evento"/>
          </div>
        )}
      </div>
    );
  };
      
  return (
    <div className="bg-marmo">
      <Helmet>
        <title>Pietro Rinaldi Parrucchiere: Gallery</title>
      </Helmet>
    <Navbar/>
    <div className="custom-margin-top">

      <div className="container-fluid bg-dark bg-opacity-50 pb-5 pt-4">
        <div className="container">
            <div className="row d-flex justify-content-center align-items-center text-center" id="CategoriesResponsive">
              <div className="col-12">
                <h2 className="text-animate-gold fw-bold fs-1">Trova Il tuo taglio</h2>
              </div>
                <div className="col-lg-2 col-10 d-flex ms-lg-4 justify-content-center align-items-center mt-4">
                    <SearchBar onSuggestionsChange={handleSuggestionsChange}/>
                </div>
            </div>
        </div>
    </div>
      <div className="container-fluid bg-dark bg-opacity-50">
        <div className="col-12 col-md-6 mx-auto">
          <div className="row d-flex justify-content-center align-items-center">
          {filteredPosts.slice(0, visiblePosts).map(post => {
            if (post.Stato === 1){
              return(
              <div className="col-4 px-0 border-custom-transparent bg-animate-gold" key={post.id}>
                  <ImageComponent imageUrl={post.ImgCopertina} />
              </div>
              )
            }
          }
          )}
          {!hasPosts || filteredPosts.length === 0 ? (
            <p className="fs-1 text-default fw-bold text-center my-5">Nessun post trovato</p>
          ) : null}
          </div>
        </div>
        <div className="row d-flex justify-content-center align-items-center">
          {visiblePosts < filteredPosts.length && (
          <div className="col-12 text-center my-4">
          <button className="btn-default rounded-pill px-3 py-2" onClick={handleShowMore}>Mostra di più</button>
          </div>
          )}
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Gallery

