import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Gallery from "./pages/Gallery";
import HomePage from "./pages/HomePage";
import Prodotti from "./pages/Prodotti";
import WorkInProgress from "./pages/WorkInProgress";

import "./index.css";
import "./blog.css";





function App() {

  return (
    <div className="App">
        <Router>
            <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/*" element={<HomePage />} />
                <Route exact path="/gallery" element={<Gallery/>} />
                <Route exact path="/prodotti" element={<Prodotti />} />
                <Route exact path="/work-in-progress" element={<WorkInProgress />} />
            </Routes>   
        </Router>
    </div>
  );
}

export default App;
