import React from 'react';
import { Typewriter } from 'react-simple-typewriter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons';
import WorkingProgressSVG from "../components/WorkingProgressSVG"

function WorkInProgress() {

    const handleGoBack = () => {
        window.history.back();
    };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
      }}
    >
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-8 col-md-4 mt-5">
            <WorkingProgressSVG/>
          </div>
          <div className="col-12 text-center my-3">
            <span className="p-0 m-0 fs-1 text-default text-uppercase">
            <Typewriter
                words={['Questa pagina è in costruzione...']}
                cursor
                cursorStyle=""
                typeSpeed={70}
                delaySpeed={1000} 
            /></span>
          </div>
          <div className="col-12 text-center">
            <button className="btn btn-default mt-3 px-3 py-2" onClick={handleGoBack}><FontAwesomeIcon className="fs-5 text-light me-2" icon={faReply}/>Torna indietro</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkInProgress;
